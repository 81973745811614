<template>
    <div class="pa-0 ma-0">
        <v-card flat style="background-color: var(--v-background-base) !important" class="pa-0 ma-0">
            <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55"
                class="pa-0 ma-0">
                <v-toolbar-title>
                    <b class="mr-3">Organisation Merging</b>
                </v-toolbar-title>
            </v-toolbar>

            <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
                'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
            }">
                <v-card-text :style="{
                    height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                    'overflow-y': 'auto',
                }">
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" style="height: 5vh">
                            <v-row no-gutters>
                                <!-- <v-col cols="12" sm="2" md="2" lg="2" class="text-center">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-select label="Choose Dark Matter Column" :items="formattedPartyOptions"
                                                outlined dense @change="handlePartyOptionChange"
                                                v-model="selectedPartyOption">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="12" sm="2" md="2" lg="2" class="text-center">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn v-if="this.selectedPartyOption.name === 'Consignee'" color="primary"
                                                @click="getOrganisationDetails = true">
                                                Search for Organisations
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col> -->
                                <!-- <v-col v-if="selectedOrganisationOne" cols="12" sm="3" md="3" lg="3" class="text-center">
                                    <img :src="selectedOrganisationOne.logo" alt="Organization Logo">

                                </v-col>
                                <v-col v-if="selectedOrganisationOne" cols="12" sm="6" md="6" lg="6" justify="center">
                                    <v-row>
                                        <v-col cols="9">
                                            <v-card style="width: 800px;" fluid>
                                                <v-card-title>
                                                    <span class="headline">Organisation Address Information</span>
                                                </v-card-title>
                                                <v-card-text style="max-height: 10vh; overflow-y: auto;">
                                                    <v-list dense>
                                                        <v-list-item
                                                            v-for="address in selectedOrganisationOne.organisationAddresses"
                                                            :key="address.id">
                                                            <v-list-item-content>
                                                                <v-list-item-title style="word-wrap: break-word;">
                                                                    <v-icon small class="mr-1">location_on</v-icon>
                                                                    {{ `${address.addressLine1}, ${address.addressLine2},
                                                                                                                                        ${address.addressLine3}, ${address.addressLine4},
                                                                                                                                        ${address.postalCode}` }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col> -->
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" style="height: 60vh">
                            <v-card style="height: 85vh" outlined>
                                <v-card-title>
                                    <span class="headline">Organisations ({{ this.unmappedOptions.length }})</span>
                                </v-card-title>
                                <el-input clearable v-model="unmappedSearch" placeholder="Search" outlined dense>
                                    <template slot="append">
                                        <span>
                                            <v-btn @click="dialogFilter = true" icon>
                                                <v-icon>
                                                    filter_alt
                                                </v-icon>
                                            </v-btn>
                                        </span>
                                    </template>
                                </el-input>
                                <div style="display: flex; justify-content: center;">
                                    <el-pagination style="color: var(--v-primaryText-base)"
                                        :current-page.sync="pageUnmapped" :pager-count="5"
                                        :page-size.sync="paramsUnmapped.limit" :page-sizes="[5, 10, 15, 25]"
                                        :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper' : 'sizes, prev, pager, next'"
                                        :total="unmappedOptions.length"></el-pagination>
                                </div>
                                <v-card-text style="max-height: 68vh; overflow-y: auto;">
                                    <v-progress-linear v-if="unmappedOptionsLoading" indeterminate
                                        color="primary"></v-progress-linear>
                                    <v-list dense>
                                        <v-list-item>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on">
                                                        <v-checkbox @click="selectAllOptions"
                                                            v-model="selectAll"></v-checkbox>
                                                    </span>
                                                </template>
                                                <span>Select All</span>
                                            </v-tooltip>
                                            <v-list-item-content class="text-left" style="width: 200px;">
                                                <v-list-item-title><b>Name</b></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-right">
                                                <v-list-item-subtitle><b>LogLive ID(s)</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-for="option in filteredUnmappedOptions" :key="option.name">
                                            <v-checkbox @click="saveToSelectedUnmapped(option)"
                                                v-model="option.selected"></v-checkbox>
                                            <v-list-item-content class="text-left" style="width: 200px;">
                                                <v-list-item-title>{{ option.name }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-right">
                                                <v-list-item-subtitle>{{ option.ids }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6" style="height: 65vh">
                            <v-card style="height: 85vh" outlined>
                                <v-card-title>
                                    <span class="headline">Merging Panel</span>
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn color="primary" @click="getOrganisationDetails = true"
                                        :disabled="this.unmappedItems.length === 0">
                                        Search for Organisations
                                    </v-btn> -->
                                    <v-btn icon color="primary" right @click="unmappedItems = []">
                                        <v-tooltip top>
                                            <template #activator="{ on }">
                                                <v-icon v-on="on">clear_all</v-icon>
                                            </template>
                                            <span>Clear All</span>
                                        </v-tooltip>
                                    </v-btn>

                                    <v-btn icon color="red" right @click="removeItem" :disabled="isMergeDisabled">
                                        <v-tooltip top>
                                            <template #activator="{ on }">
                                                <v-icon v-on="on">backspace</v-icon>
                                            </template>
                                            <span>Remove Item</span>
                                        </v-tooltip>
                                    </v-btn>

                                    <v-btn icon color="green" right @click="mergeRows" :disabled="isMergeDisabled">
                                        <v-tooltip top>
                                            <template #activator="{ on }">
                                                <v-icon v-on="on">merge</v-icon>
                                            </template>
                                            <span>Merge Rows</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text style="max-height: 68vh; overflow-y: auto;">
                                    <v-card color="greyBase">
                                        <v-card-title>
                                            <span class="headline">Organisation Details</span>
                                        </v-card-title>
                                        <v-data-table :headers="unmappedHeaders" :items="unmappedItems" disable-pagination
                                            hide-default-footer @click:row="handleRowClick">
                                            <template v-slot:item.actions="{ item }">
                                                <v-row align="center">
                                                    <v-col>
                                                        <v-tooltip top>
                                                            <template #activator="{ on }">
                                                                <div>
                                                                    <v-checkbox color="green" v-model="item.selected"
                                                                        :disabled="item.disabled"
                                                                        @change="handleCheckboxChange(item)"
                                                                        v-on="on"></v-checkbox>
                                                                </div>
                                                            </template>
                                                            <span>Select</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template v-slot:item.ShippingProfiles="{ item }">
                                                <v-btn text @click="openDialog(item), dialogMergement = false">{{
                                                    item.ShippingProfiles }}</v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-card>

                                </v-card-text>
                            </v-card>
                            <!-- <v-card style="height: 80vh" outlined>
                                <v-card-title>
                                    <span class="headline">Inactive Organisations ({{ this.mappedOptions.length }})</span>
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <el-input v-model="mappedSearch" placeholder="Search" outlined dense>
                                    <template slot="append">
                                        <el-button v-if="showMappingButton" icon
                                            @click="mapBillingParties(unmappedOptions)"> <v-icon>start</v-icon>

                                        </el-button>
                                    </template>
                                </el-input>

                                <v-card-text style="max-height: 68vh; overflow-y: auto;">
                                    <v-progress-linear v-if="mappedOptionsLoading" indeterminate
                                        color="primary"></v-progress-linear>
                                    <div style="display: flex; justify-content: center;">
                                        <el-pagination style="color: var(--v-primaryText-base)"
                                            :current-page.sync="pageMapped" :pager-count="5"
                                            :page-size.sync="paramsMapped.limit" :page-sizes="[5, 10, 15, 25]"
                                            :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper, total' : 'sizes, prev, pager, next'"
                                            :total="mappedOptions.length">
                                        </el-pagination>
                                    </div>
                                    <v-list dense>
                                        <v-list-item>
                                            <v-list-item-content class="text-center">
                                                <v-list-item-subtitle><b>Actions</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-center">
                                                <v-list-item-title><b>Name</b></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-center">
                                                <v-list-item-subtitle><b>LogLive ID</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-for="option in filteredMappedOptions" :key="option.name">
                                            <v-list-item-content class="text-center">
                                                <v-btn small plain @click="unmapFunc(option)">
                                                    <v-icon color="success">arrow_back</v-icon>
                                                </v-btn>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-center">
                                                <v-list-item-title>{{ option.name }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-center">
                                                <v-list-item-subtitle>{{ option.id }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                            </v-card> -->
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card>

        <v-dialog v-model="getOrganisationDetails" persistent width="1200px" scrollable>
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Organisation Details</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon right @click="getOrganisationDetails = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <OrganisationMappingLookup :selection="selectedOrganisationOne" :item="item"
                        @refresh="getRelations(), clear()" @close="(relationshipDialog = false), (item = {})"
                        @selection="handleSelection" />

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogReactivateWarning" persistent width="400px" scrollable>
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Reactivate Organisation?</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon color="success" right
                            @click="dialogReactivateWarning = false; commenceUnmap(); showSnackbar('Reactivation successful', 'success')">
                            <v-icon>check</v-icon>
                        </v-btn>
                        <v-btn icon color="red" right @click="dialogReactivateWarning = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <p>Are you sure that you want to reactivate the organisation immediately?</p>

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMergement" persistent width="1200px" scrollable>
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">{{ selectedItemName }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon color="green" right @click="mergeRows" :disabled="isMergeDisabled">
                            <v-icon>merge</v-icon>
                        </v-btn>
                        <v-btn icon color="red" right @click="dialogMergement = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-data-table :headers="unmappedHeaders" :items="unmappedItems" disable-pagination hide-default-footer
                        @click:row="handleRowClick">
                        <template v-slot:item.actions="{ item }">
                            <v-row align="center">
                                <v-col>
                                    <v-tooltip top>
                                        <template #activator="{ on }">
                                            <v-btn @click="disableFunc(item.id)" icon color="red" :disabled="item.isDeleted"
                                                v-on="on">
                                                <v-icon>disabled_by_default</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Disable</span>
                                    </v-tooltip>
                                </v-col>

                                <v-col>
                                    <v-tooltip top>
                                        <template #activator="{ on }">
                                            <v-checkbox color="green" v-model="item.selected" :disabled="item.disabled"
                                                @change="handleCheckboxChange(item)" v-on="on"></v-checkbox>
                                        </template>
                                        <span>Select</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.ShippingProfiles="{ item }">
                            <v-btn text @click="openDialog(item), dialogMergement = false">{{ item.ShippingProfiles
                            }}</v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogSP" persistent width="1200px" scrollable>
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Shipping Profiles of {{ selectedItemName }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon color="red" right @click="dialogSP = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-data-table :headers="spHeaders" :items="spItems" disable-pagination hide-default-footer>
                        <template v-slot:item="{ item }">
                            <tr :key="item.id"> <!-- Add the key directive with a unique key value, e.g., item.id -->
                                <td v-for="(value, index) in item" :key="index"
                                    :style="{ color: value === selectedItemId ? 'green' : 'inherit' }">
                                    {{ value }}
                                </td>

                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDisable" persistent width="400px" scrollable>
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Disable Organisation?</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon color="success" right
                            @click="dialogDisable = false; commenceDisable(); showSnackbar('Disabling successful', 'success')">
                            <v-icon>check</v-icon>
                        </v-btn>
                        <v-btn icon color="red" right @click="dialogDisable = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <p>Are you sure that you want to disable the organisation?</p>

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogFilter" width="400px">
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Advanced filter</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon right @click="filterStatus = null; filterId = null">
                            <v-icon>filter_alt_off</v-icon>
                        </v-btn>
                        <v-btn icon color="red" right @click="dialogFilter = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-list>
                        <v-list-group v-model="activeGroup">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>Status</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-switch color="primary" v-model="filterStatus" value="activeOrg"
                                        label="Active Organisations"></v-switch>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-switch color="primary" v-model="filterStatus" value="inactiveOrg"
                                        label="Inactive Organisations"></v-switch>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-group v-model="idGroup">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>IDs</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-text-field v-model="idFilterValue" min=0 type="number" inputmode="numeric"
                                label="Enter Organisation ID:"></v-text-field>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-switch color="primary" v-model="filterId" value="equalToID"
                                        label='Find Organisation By ID' :disabled="!idFilterValue"></v-switch>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-switch color="primary" v-model="filterId" value="multipleIDs"
                                        label='Multiple Organisation LogLive IDs'></v-switch>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-switch color="primary" v-model="filterId" value="singularID"
                                        label='Singular Organisation LogLive ID'></v-switch>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbarVisible" :color="snackbarColor" :timeout="snackbarTimeout">{{ snackbarText
        }}</v-snackbar>
    </div>
</template>

  
<script>
import OrganisationMappingLookup from "../components/SystemComponents/OrganisationMappingLookup.vue";
export default {
    components: { OrganisationMappingLookup },
    data: () => ({
        selectedItemName: null,
        selectAll: false,
        selectedOrgs: [],
        mergeArray: [],     // Array to store checked checkboxes
        primaryOrg: null,   // Variable to store the value of the singular switch
        selectedMapped: null,
        selectedCheckbox: null,
        unmappedItems: [],
        unmappedHeaders: [],
        dialogMergement: false,
        dialogReactivateWarning: false,
        dialogSP: false,
        dialogDisable: false,
        disabledId: null,
        spItems: [],
        spHeaders: [],
        selectedItemId: null,
        selectedUnmapped: "",
        selectedRow: null, // Add selectedRow property
        selectedPartyOption: null,
        unmappedOptionsLoading: false,
        mappedOptionsLoading: false,
        getOrganisationDetails: false,
        partyOptions: [
            { name: "Billing Party", recordCount: "100" },
            { name: "Exporter", recordCount: "200" },
            { name: "Consignee", recordCount: "50" },
        ],
        unmappedOptions: [],
        mappedOptions: [],
        unmappedSearch: '',
        mappedSearch: '',
        contactModal: false,
        contact: {},
        countries: [],
        countryKey: 0,
        headers: [
            {
                text: "Name",
                value: "relatedOrganisation.name",
                sortable: true,
                align: "left",
            },
            {
                text: "Country",
                value: "relatedOrganisation.countryRegion",
                sortable: false,
                align: "center",
            },
            {
                text: "Linked Profiles",
                value: "linkedProfiles",
                align: "center",
            },
            {
                text: "Customer",
                value: "isCustomer",
                sortable: true,
                align: "center",
            },

            {
                text: "Supplier",
                value: "isSupplier",
                sortable: true,
                align: "center",
            },
            {
                text: "Teams",
                value: "teams",
                sortable: false,
                align: "center",
            },
            {
                text: "Network Functions",
                sortable: false,
                filterable: true,
                value: "networkFunctions",
                align: "center",
            },
            {
                text: "Status",
                value: "isActive",
                sortable: true,
                align: "center",
            },
        ],
        loading: false,
        loadingUsers: false,
        isLoading: false,
        loadingTable: false,
        page: 1,
        params: {
            limit: 15,
            offset: 0,
            search: null,
            filter: { isActive: [true] },
            teamFilter: null,
            noTeams: false
        },
        networkFunctions: [
            {
                name: "Producer",
                attribute: "producer",
                selected: true,
            },
            {
                name: "Coldstore",
                attribute: "coldstore",
                selected: true,
            },
            {
                name: "Consignee",
                attribute: "consignee",
                selected: true,
            },
            {
                name: "Shipper",
                attribute: "shipper",
                selected: true,
            },
            {
                name: "Forwarder",
                attribute: "forwarder",
                selected: true,
            },
            {
                name: "Buyer",
                attribute: "buyer",
                selected: true,
            },
            {
                name: "Notify Party",
                attribute: "notifyParty",
                selected: true,
            },
        ],
        relationshipDialog: false,
        item: {},
        tags: [],
        tagKey: 0,
        organisations: {
            count: 0,
            data: [],
        },
        settings: {},
        tab: "0",
        tagSearch: "",
        timeout: null,
        filterDialog: false,
        filterActive: false,
        selectedFilterCountry: { name: '', iso: '' },
        loadingCountries: false,
        searchTimer: undefined,
        orgTeams: [],
        searchItem: {
            isActive: true,
            inActive: false,
            isCustomer: true,
            isSupplier: true,
            teams: [],
            country: ''
        },
        updateBadge: 2000,
        selectedOrganisationOne: null,
        selectedOrganisationTwo: null,
        snackbarVisible: false,
        snackbarColor: '',
        snackbarTimeout: 3000,
        snackbarText: '',
        mergeDependentLL: null,
        mergeDependencyLL: null,
        pageMapped: 1,
        paramsMapped: {
            limit: 10, // Initial page size
            offset: 0
        },
        pageUnmapped: 1,
        paramsUnmapped: {
            limit: 10, // Initial page size
            offset: 0
        },
        filterStatus: null,
        dialogFilter: false,
        orgIdArray: [],
        idFilterValue: 0,
        filterId: 0,
        searchTimeout: null,
    }),
    watch: {
        filterStatus() {
            this.getDistinctOrgs();
        },
        filterId: {
            handler(newVal) {
                this.getDistinctOrgs();
            },
            immediate: true, // Calls the handler immediately on component mount
        },
        unmappedSearch: {
            handler: function () {
                setTimeout(() => {
                    this.getDistinctOrgs();
                }, 3000);
            },
            immediate: true
        },
        selectedRow(newRow, oldRow) {
            if (oldRow && !oldRow.selected) {
                this.selectedRow = null;
            }
        },
        // networkFunctionKey() {
        //   this.networkFunctions.forEach((x) => {
        //     this.params.filter[x.attribute] = x.selected;
        //   });
        //   this.getRelations();
        // },
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit;
            await this.getRelations();
        },
        // "params.limit": {
        //   immediate: true,
        //   async handler() {
        //     await this.getRelations();
        //   },
        // },
        // 'params.search': {
        //   async handler() {
        //     this.page = 1;
        //     await this.getRelations()
        //   }
        // },
        selectedFilterCountry(value) {
            if (value && value.name) {
                this.searchItem.country = value.name
            }
        },
        // sortBy(value) {
        //   this.params.sortBy = value
        //   this.getRelations()
        // },
        // sortDesc(value) {
        //   this.params.sortDesc = value
        //   this.getRelations()
        // },
        pageUnmapped: {
            handler: async function () {
                this.selectAllUnmapped = false
                this.paramsUnmapped.offset = (this.pageUnmapped - 1) * this.paramsUnmapped.limit;
                this.getDistinctOrgs()
                await this.getRelations();
            },
            immediate: true
        },
    },
    computed: {
        filteredOptions() {
            this.getDistinctOrgs();
            return this.unmappedOptions;
        },
        showMappingButton() {
            return this.selectedPartyOption.name === 'Billing Party' || this.selectedPartyOption.name === 'Exporter';
        },
        isMergeDisabled() {
            return this.unmappedItems.filter(item => item.selected).length === 0;
        },
        darkMatterColumnDisabled() {
            return !this.selectedOrganisationOne;
        },
        formattedPartyOptions() {
            return this.partyOptions.map((option) => ({
                value: option,
                text: `${option.name}`,
            }));
        },
        formattedOrgOptions() {
            return this.orgOptions.map((option) => ({
                value: option,
                text: `${option.name} [${option.countryCode}]`,
            }));
        },
        filteredOrganisations() {
            let result = this.organisations.data
            let filter = {}
            // this.params.filter = {}
            if (this.searchItem.isActive && this.searchItem.inActive) { filter.isActive = [true, false] }
            else if (this.searchItem.isActive && !this.searchItem.inActive) { filter.isActive = [true] }
            else if (!this.searchItem.isActive && this.searchItem.inActive) { filter.isActive = [false] }
            filter.isSupplier = [this.searchItem.isSupplier]
            filter.isCustomer = [this.searchItem.isCustomer]
            if (this.params.noTeams) { filter.teamFilter = null }
            else { filter.teamFilter = this.searchItem.teams }
            filter = JSON.parse(JSON.stringify(filter))
            filter.teamFilter = JSON.parse(JSON.stringify(filter.teamFilter))

            if (filter.teamFilter && filter.teamFilter.length > 0) {
                result = result.filter(x => x.relationshipTeams && x.relationshipTeams.some(y => filter.teamFilter.includes(y.teamId)))
            }
            if (filter.isActive && filter.isActive.length > 0) {
                result = result.filter(x => filter.isActive.includes(x.isActive))
            }
            if (filter.isSupplier.length > 0 || filter.isCustomer.length > 0) {
                result = result.filter(x => filter.isCustomer.includes(x.isCustomer) || filter.isSupplier.includes(x.isSupplier))
            }
            // for(let i =0;i<keys.length;i++){
            //   if(this.params.filter[keys[i]] && this.params.filter[keys[i]].length > 0){
            //     // delete this.params[keys[i]]
            //     result = result.filter(x=>x[keys[i]] && this.params.filter[keys[i]].includes(x[keys[i]]))
            //   }
            // }
            // this.filterActive = true
            // this.filterDialog = false
            // this.updateBadge ++
            return result
        },
        filteredUnmappedOptions() {
            // const start = (this.pageUnmapped - 1) * this.paramsUnmapped.limit;
            // const end = start + this.paramsUnmapped.limit;
            // const options = this.unmappedOptions.slice(start, end);

            // return this.filterAndSortOptions(options, this.unmappedSearch);
            return this.unmappedOptions
        },
        // filteredMappedOptions() {
        //     const start = (this.pageMapped - 1) * this.paramsMapped.limit;
        //     console.log(start)
        //     const end = start + this.paramsMapped.limit;
        //     console.log(end)
        //     console.log(this.mappedOptions.slice(start, end))
        //     return this.filterAndSortOptions(this.mappedOptions.slice(start, end), this.mappedSearch);
        // }

    },
    methods: {
        selectAllOptions() {
            for (const option of this.filteredUnmappedOptions) {
                option.selected = this.selectAll;
                this.saveToSelectedUnmapped(option);
            }
        },
        async getOrgIds() {
            const response = await this.$API.getDistinctOrgs();
            const orgArray = response;
            const ids = orgArray.map(org => org.ids.split(", ")).flat();
            const sortedIds = ids.sort((a, b) => a - b);
            this.orgIdArray = sortedIds;
        },
        filterAndSortOptions(options, search) {
            if (!search) {
                return options.sort((a, b) => a.name.localeCompare(b.name));
            }

            const searchLower = search.toLowerCase();
            return options
                .filter(option =>
                    option.name.toLowerCase().includes(searchLower) ||
                    option.id.toLowerCase().includes(searchLower)
                )
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        async updateUnmappedList(option) {
            const index = this.unmappedOptions.findIndex(item => item.name === option.name);
            if (index !== -1) {
                const removedItem = this.unmappedOptions.splice(index, 1)[0];
                this.mappedOptions.push(removedItem);
            }
        },
        // async updateMappedList(option) {
        //     const index = this.mappedOptions.findIndex(item => item.name === option.name);
        //     if (index !== -1) {
        //         const removedItem = this.mappedOptions.splice(index, 1)[0];
        //         this.unmappedOptions.push(removedItem);
        //     }
        // },
        handleCheckboxChange(item) {
            if (this.selectedCheckbox === item) {
                // If the same checkbox is clicked again, unselect it and enable all checkboxes
                this.selectedCheckbox = null;
                this.unmappedItems.forEach((i) => {
                    i.disabled = false;
                });
            } else {
                // Unselect the previously selected checkbox and enable all checkboxes
                if (this.selectedCheckbox) {
                    this.selectedCheckbox.selected = false;
                    this.selectedCheckbox.disabled = false;
                }

                // Select the clicked checkbox and disable all other checkboxes
                item.selected = true;
                item.disabled = true;
                this.selectedCheckbox = item;
            }
        },
        async mergeRows() {
            const selectedRow = this.unmappedItems.find(item => item.selected);
            // console.log(selectedRow)
            if (selectedRow) {
                this.mergeDependentLL = selectedRow.id;
                const selectedOrgs = this.unmappedItems.filter(item => item.id !== selectedRow.id)
                    .map(item => item.id);

                console.log(selectedOrgs);

                if (selectedOrgs.length > 0) {
                    for (const orgId of selectedOrgs) {
                        this.mergeDependencyLL = orgId;
                        console.log(`${this.mergeDependencyLL} = ${this.mergeDependentLL}`);
                        const response = await this.$API.newDMRule({
                            oldLLId: this.mergeDependencyLL,
                            newLLId: this.mergeDependentLL,
                            userId: this.$store.state.user.id
                        });
                        this.showSnackbar("Dark Matter Rule Set", "success");
                    }
                }
            }
        },
        unmapFunc(option) {
            this.dialogReactivateWarning = true
            // this.selectedMapped = {
            //     cbrID: cbrId,
            //     ID: id
            // }
            this.selectedMapped = option
        },
        async commenceUnmap() {
            // this.updateMappedList(this.selectedMapped)
            const id = this.selectedMapped.id;
            const response = await this.$API.setAsActive()
        },
        async disableFunc(id) {
            this.dialogDisable = true;
            this.disabledId = id

            // Make the POST request
        },
        async handleRowClick(row) {
            if (row.selected) {
                this.selectedRow = row.id; // Assuming the ID property of the row object is called "id"
                // Rest of the method code
                console.log(this.selectedRow);
            }
        },
        async removeItem() {
            if (this.selectedRow) {
                this.unmappedItems = this.unmappedItems.filter(item => item.id !== this.selectedRow);
            }
        },
        async openDialog(item) {
            this.dialogSP = true;
            this.selectedItemName = item.name
            console.log(this.selectedItemName)
            this.selectedItemId = item.id;
            console.log(this.selectedItemId)
            const response = await this.$API.countConsigneeProfiles(this.selectedItemId);
            const records = response.records
            const firstObject = records[0]
            const headers = [
                ...Object.keys(firstObject).map((key) => ({
                    text: key.charAt(0).toUpperCase() + key.slice(1),
                    value: key,
                })),
            ];

            const spItems = records.map((result) => {
                const item = {};
                headers.forEach((header) => {
                    item[header.value] = result[header.value];
                });
                return item;
            });

            this.spHeaders = headers;
            this.spItems = spItems;
            console.log(headers);
            console.log(spItems)
        },
        async saveToSelectedUnmapped(option) {
            if (option.selected) {
                const idsStr = option.ids;
                const ids = idsStr.replace(/\s/g, '').split(",").map(Number);
                console.log(ids);
                const existingItem = this.unmappedItems.find(item => ids.includes(Number(item.id)));

                if (existingItem) {
                    // Display snackbar message
                    this.snackbarText = "Object with the same id already exists.";
                    this.snackbarColor = "red";
                    this.snackbarVisible = true;
                    option.selected = false; // Uncheck the option
                } else {
                    const requests = ids.map(async (id, index) => {
                        const response = await this.$API.findOrg(id);
                        const userResponse = await this.$API.countOrgUsers(id);
                        const spResponse = await this.$API.countConsigneeProfiles(id);
                        const bookingResponse = await this.$API.countBookings(id);
                        const consigneeProfileRelationshipResponse = await this.$API.countConsigneeProfileRelationships(id)
                        const organisationAddressResponse = await this.$API.countOrganisationAddresses(id)
                        const shipmentRelationshipResponse = await this.$API.countShipmentRelationships(id)
                        const shipmentResponse = await this.$API.countShipments(id)
                        const shipmentFileResponse = await this.$API.countShipmentFiles(id)

                        const item = {
                            userCount: userResponse, // Assign userResponse.data to userCount
                            organisationAddressCount: organisationAddressResponse.count,
                            bookingsCount: bookingResponse.bookingsCount,
                            ShippingProfiles: spResponse.count,
                            consigneeProfileRelationshipCount: consigneeProfileRelationshipResponse.count,
                            shipmentRelationshipCount: shipmentRelationshipResponse.count,
                            shipmentCount: shipmentResponse.count,
                            shipmentFileCount: shipmentFileResponse.count,
                            ...response, // Spread the response data

                        };

                        return item;
                    });

                    // console.log(requests)
                    try {
                        const results = await Promise.all(requests);
                        console.log(results)
                        const firstObject = results[0];
                        const headers = [
                            {
                                text: 'Select Main Organisation:',
                                value: 'actions',
                                sortable: false,
                                width: "150px"
                            },
                            ...Object.keys(firstObject).map((key) => ({
                                text: key.charAt(0).toUpperCase() + key.slice(1),
                                value: key,
                            })),
                        ];

                        const unmappedItems = results.map((result) => {
                            const item = { ...result };
                            const hasZeroValues = result.ShippingProfiles === 0 || result.ShipperBookings === 0 || result.BillingBookings === 0;
                            item.actions = hasZeroValues;
                            return item;
                        });

                        this.unmappedHeaders = headers;
                        // this.unmappedItems = unmappedItems;
                        for (let i = 0; i < unmappedItems.length; i++) {
                            this.unmappedItems.push(unmappedItems[i])
                        }
                        // console.log("Unmapped Items Array")
                        // console.log(this.unmappedItems);
                        this.selectedUnmapped = option;
                        console.log(this.selectedUnmapped)
                    } catch (error) {
                        console.error(error);
                    }
                }
            } else {
                // Remove the option from the array if it was unchecked
                const idToRemove = option.ids;
                console.log(idToRemove)
                const index = this.unmappedItems.findIndex(item => item.id === parseInt(idToRemove));
                console.log(index)
                if (index > -1) {
                    this.unmappedItems.splice(index, 1);
                }
            }

        },
        handlePartyOptionChange(selectedOption) {
            console.log(this.selectedPartyOption)
            if (selectedOption && selectedOption.name === "Billing Party") {
                this.getBillingPartyCBROrgs();
            } else if (selectedOption && selectedOption.name === "Exporter") {
                this.getExporterCBROrgs();
            } else {
                this.getConsigneeCBROrgs();
            }
        },
        async getDistinctOrgs() {
            try {
                const limit = this.paramsUnmapped.limit;
                const offset = this.paramsUnmapped.offset
                let params = {};

                if (limit) {
                    params.limit = limit
                }

                if (offset) {
                    params.offset = offset
                }

                if (this.filterStatus === "activeOrg") {
                    params = { isActive: true, isDeleted: false };
                } else if (this.filterStatus === "inactiveOrg") {
                    params = { isActive: false, isDeleted: true };
                }

                // Apply filter if this.unmappedSearch has a value
                if (this.unmappedSearch) {
                    params.name = this.unmappedSearch;
                }

                // Apply ID filter if filterId is "equalToID" and idFilterValue has a value
                if (this.filterId === "equalToID" && this.idFilterValue) {
                    params.id = this.idFilterValue;
                }

                // Apply additional filter for "multipleIDs"
                if (this.filterId === "multipleIDs") {
                    params.multipleIDs = true;
                }

                // Apply additional filter for "singularID"
                if (this.filterId === "singularID") {
                    params.singularID = true;
                }

                const response = await this.$API.getDistinctOrgs(params);

                const distinctOrgs = response.map((item) => item);
                this.unmappedOptions = distinctOrgs;
                console.log(this.unmappedOptions);
            } catch (error) {
                console.error("An error occurred while fetching distinct orgs: ", error);
                throw error;
            }
        },
        // async mapBillingParties(cbrCertifiedOrgs) {
        //     try {
        //         const response = await axios.post("http://localhost:3000/updateBP", {
        //             cbrCertifiedOrgs: cbrCertifiedOrgs
        //         });

        //         // Call the showSnackbar method with success message and color
        //         this.showSnackbar('Mapping parties successful', 'success');
        //     } catch (error) {
        //         console.error(error);

        //         // Call the showSnackbar method with error message and color
        //         this.showSnackbar('Error mapping parties', 'error');
        //     }
        // },
        showSnackbar(text, type) {
            this.snackbarText = text;
            this.snackbarColor = type === 'success' ? 'success' : 'error';
            this.snackbarVisible = true;
        },
        async handleSelection(row) {
            // row is the emitted value
            // console.log(row); 
            this.selectedOrganisationOne = row
            this.getOrganisationDetails = false
            // console.log(this.selectedOrganisationOne)
            const ids = [this.selectedOrganisationOne.id]
            // console.log(idArray)
            const requests = ids.map(async (id, index) => {
                const response = await this.$API.findOrg(id);
                const userResponse = await this.$API.countOrgUsers(id);
                const spResponse = await this.$API.countConsigneeProfiles(id);
                const bookingResponse = await this.$API.countBookings(id);

                const item = {
                    userCount: userResponse, // Assign userResponse.data to userCount
                    bookingsCount: bookingResponse.bookingsCount,
                    ShippingProfiles: spResponse.count,
                    ...response, // Spread the response data

                };

                return item;
            });

            // console.log(requests)
            try {
                const results = await Promise.all(requests);
                console.log(results)

                const unmappedItems = results.map((result) => {
                    const item = { ...result };
                    const hasZeroValues = result.ShippingProfiles === 0 || result.ShipperBookings === 0 || result.BillingBookings === 0;
                    item.actions = hasZeroValues;
                    return item;
                });

                console.log(unmappedItems)
                for (let i = 0; i < unmappedItems.length; i++) {
                    this.unmappedItems.push(unmappedItems[i])
                }
                console.log(this.unmappedItems)
                // console.log("Unmapped Items Array")
                // console.log(this.unmappedItems);
            } catch (error) {
                console.error(error);
            }
        },
        toggleChecked(option) {
            option.checked = !option.checked;
        },
        addRelationship() {
            this.item = {
                modalType: "Add",
                relatedOrganisation: {
                    organisationUsers: [],
                },
                organisationTags: [],
                producer: false,
                coldstore: false,
                consignee: false,
                buyer: false,
                forwarder: false,
                notifyParty: false,
                shipper: false,
                transporter: false,
                shippingLine: false,
                jointShipper: false,
                jointForwarder: false,
                jointBuyer: false,
                inverse: {
                    producer: this.settings.producer,
                    coldstore: this.settings.coldstore,
                    consignee: this.settings.consignee,
                    buyer: this.settings.buyer,
                    forwarder: this.settings.forwarder,
                    notifyParty: this.settings.notifyParty,
                    shipper: this.settings.shipper,
                    transporter: this.settings.transporter,
                    shippingLine: this.settings.shippingLine,
                },
                isCustomer: false,
                isSupplier: false,
                relationshipTeams: [],
            };
            this.relationshipDialog = true;
        },


        async changeActiveStatus(item) {
            item.isActive = !item.isActive;
            await this.$API.updateRelation(item);
        },
        computeFilterCount() {
            let filter = {}
            // this.params.filter = {}
            if (this.searchItem.isActive && this.searchItem.inActive) { filter.isActive = [true, false] }
            else if (this.searchItem.isActive && !this.searchItem.inActive) { filter.isActive = [true] }
            else if (!this.searchItem.isActive && this.searchItem.inActive) { filter.isActive = [false] }
            filter.isSupplier = [this.searchItem.isSupplier]
            filter.isCustomer = [this.searchItem.isCustomer]
            if (this.params.noTeams) { filter.teamFilter = null }
            else { filter.teamFilter = this.searchItem.teams }
            filter = JSON.parse(JSON.stringify(filter))
            filter.teamFilter = JSON.parse(JSON.stringify(filter.teamFilter))
            let keys = Object.keys(filter);
            let count = 0;
            for (let i = 0; i < keys.length; i++) {
                if (filter[keys[i]] && filter[keys[i]][0] === true) {
                    count++;
                } else if (keys[i] == 'teamFilter' && filter.teamFilter != null && filter[keys[i]].length > 0) {
                    count++;
                }
            }
            return count;
        },
        async getTeams() {
            let teams = await this.$API.getTeams(this.orgId)
            this.orgTeams = teams
        },
        async getUserTeams() {
            this.myTeams = await this.$API.getUserTeams()
            this.searchItem.teams = this.myTeams.map(x => x.teamId)
            // this.filterSearch()
        },
        async getCountries() {
            this.loadingCountries = true
            let result = await this.$API.searchCountries();
            for (let i = 0; i < result.data.length; i++) {
                this.countries.push({
                    name: result.data[i].name,
                    iso2: result.data[i].iso2
                })
            }
            this.loadingCountries = false
        },
        editOrganisation(org) {
            this.item = org;
            this.item.modalType = "Edit";
            this.item.relationshipTeamIds = this.item.relationshipTeams.map(
                (x) => x.teamId
            );
            this.relationshipDialog = true;
        },
        async getRelations() {
            if (this.searchTimer) { clearTimeout(this.searchTimer) }
            this.loadingTable = true;
            this.searchTimer = setTimeout(async () => {
                this.organisations = await this.$API.getRelation({
                    // params: this.params,
                });
                this.loadingTable = false;
            }, 500)
        },
        async getTags() {
            this.tags = await this.$API.getTagsByType("organisation");
        },
        async handleSelect(item) {
            if (this.item.id) {
                let result = await this.$API.createOrganisationTag({
                    tagId: item.id,
                    organisationId: this.item.id,
                });
                this.item.organisationTags.push(result);
            } else {
                this.item.organisationTags.push({
                    tag: item,
                    tagId: item.id,
                    organisationId: this.item.id,
                });
            }
        },
        async getOrganisationNetworkFunctions() {
            this.settings = await this.$API.getOrganisationNetworkFunctions();
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        removeOrganisation(org) {
            this.$confirm(
                "Are you sure you want to delete this Organisation relationship?",
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            )
                .then(async () => {
                    await this.$API.updateRelation({
                        id: org.id,
                        isActive: false,
                        isDeleted: true,
                    });
                    this.getRelations();
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Delete canceled",
                    });
                });
        },
        async saveRelationship(item) {
            if (!item.id) {
                item.relatedOrganisationId = item.relatedOrganisation.id;
                item.inviteAccepted = true;
                let relation = await this.$API.createRelation(item);
                if (item.relationshipTeams) {
                    let teams = item.relationshipTeams.map((x) => ({
                        teamId: x,
                        organisationRelationshipId: relation.id,
                    }));
                    if (teams.length > 0) {
                        await this.$API.createRelationshipTeam({
                            batch: teams,
                        });
                    }
                }
                this.$message.success("Successfully Created!");
            } else {
                await this.$API.updateRelation(item);
                this.relationshipDialog = false;
                this.item = {};
                this.$message.success("Successfully Updated!");
            }
            this.getRelations();
            this.clear();
        },
        async saveOrganisation(item) {
            this.loading = true;
            if (item.id) {
                await this.updateOrganisation();
                this.$message.success("Update successful!");
            } else {
                let org = await this.$API.createOrganisation(item.relatedOrganisation)
                item.relatedOrganisationId = org.id
                item.inviteAccepted = true
                let relation = await this.$API.createRelation(item)
                if (item.relationshipTeams) {
                    let teams = item.relationshipTeams.map((x) => ({
                        teamId: x.id,
                        organisationRelationshipId: relation.id,
                    }))
                    if (teams.length > 0) {
                        await this.$API.createRelationshipTeam({
                            batch: teams,
                        })
                    }
                }

                this.$message.success("Successfully created!")
                this.getRelations()
            }
            this.clear();
        },
        async updateOrganisation() {
            await this.$API.updateRelation(this.item);
            this.clear();
        },
        clear() {
            this.loading = false
            this.relationshipDialog = false
            this.item = {
                relatedOrganisation: {},
            };
        },
        async filterSearch() {
            this.params.filter = {}
            if (this.searchItem.isActive && this.searchItem.inActive) { this.params.filter.isActive = [true, false] }
            else if (this.searchItem.isActive && !this.searchItem.inActive) { this.params.filter.isActive = [true] }
            else if (!this.searchItem.isActive && this.searchItem.inActive) { this.params.filter.isActive = [false] }
            this.params.filter.isSupplier = [this.searchItem.isSupplier]
            this.params.filter.isCustomer = [this.searchItem.isCustomer]
            if (this.params.noTeams) { this.params.teamFilter = null }
            else { this.params.teamFilter = this.searchItem.teams }
            this.params.filter = JSON.parse(JSON.stringify(this.params.filter))
            this.params.teamFilter = JSON.parse(JSON.stringify(this.params.teamFilter))
            this.filterActive = true
            this.filterDialog = false
            this.updateBadge++
            // this.getRelations()
        },
        closeFilterDialog() {
            if (!this.filterActive) { this.clearFilterDialog() }
            this.filterDialog = false
        },
        clearFilters() {
            this.clearFilterDialog()
            // this.getRelations()
        },
        async clearFilterDialog() {
            this.params.filter = { isActive: [true] }
            this.params.teamFilter = null
            this.params.noTeams = false
            this.filterDialog = false
            this.filterActive = false
            this.selectedFilterCountry = undefined
            this.page = 1
            this.searchItem = {
                country: null,
                isActive: true,
                inActive: false,
                isCustomer: true,
                isSupplier: false,
                teams: null,
            };
        },
        routeToContact(contact) {
            this.$router.push('/contact/' + contact.id)
        },
    },
    async created() {
        this.getRelations();
        this.getUserTeams()
        // this.getTags();
        this.getOrganisationNetworkFunctions();
        this.getCountries()
        this.getTeams()

        this.getDistinctOrgs()
        // this.getOrgIds()
        // this.handlePartyOptionChange({ name: "Billing Party", recordCount: "100" })
        // this.getBillingPartyCBROrgs(
    },
    mounted() {
        if (this.formattedPartyOptions.length > 0) {
            this.selectedPartyOption = this.formattedPartyOptions[0].value;
        }
    },
};
</script>

<style scoped>
.no-outline:focus {
    outline: none;
}
</style>